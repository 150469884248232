// const themeOptions = ["light-sheet", "dark-sheet", "blue-sheet", "pink-sheet"];

const getThemeById = (id) => {
    return themeOptions[id];
};

const iconColors = {
    dark: "white",
    pink: "#7D1E6A",
    darker: "#aaaaaa",
    hllwn: "#DC5F00",
    lght_olv: "white",
    crsp_sky: "white",
    yllw_wtrmln: "black",
    light: "black",
    blue: "white",
};

const themeOptions = [
    {
        id: 0,
        name: "Light",
        style: "light-sheet",
        dropdown: "light-sheet-ddmenu",
        dropdownItem: "light-sheet-dditem",
        scroll: "light-sheet-thumb",
        select: "light-sheet-select",
        iconColor: { color: iconColors.light },
    },
    {
        id: 1,
        name: "Dark",
        style: "dark-sheet",
        dropdown: "dark-sheet-ddmenu",
        dropdownItem: "dark-sheet-dditem",
        scroll: "dark-sheet-thumb",
        select: "dark-sheet-select",
        iconColor: { color: iconColors.dark },
    },
    {
        id: 2,
        name: "Blue",
        style: "blue-sheet",
        dropdown: "blue-sheet-ddmenu",
        dropdownItem: "blue-sheet-dditem",
        scroll: "blue-sheet-thumb",
        select: "blue-sheet-select",
        iconColor: { color: iconColors.blue },
    },
    {
        id: 3,
        name: "Pink",
        style: "pink-sheet",
        dropdown: "pink-sheet-ddmenu",
        dropdownItem: "pink-sheet-dditem",
        scroll: "pink-sheet-thumb",
        select: "pink-sheet-select",
        iconColor: { color: iconColors.pink },
    },
    {
        id: 4,
        name: "Darker",
        style: "darker-sheet",
        dropdown: "darker-sheet-ddmenu",
        dropdownItem: "darker-sheet-dditem",
        scroll: "darker-sheet-thumb",
        select: "darker-sheet-select",
        iconColor: { color: iconColors.darker },
    },
    {
        id: 5,
        name: "Yellow Watermellon",
        style: "yllw-wtrmln-sheet",
        dropdown: "yllw-wtrmln-sheet-ddmenu",
        dropdownItem: "yllw-wtrmln-sheet-dditem",
        scroll: "yllw-wtrmln-sheet-thumb",
        select: "yllw-wtrmln-sheet-select",
        iconColor: { color: iconColors.yllw_wtrmln },
    },
    {
        id: 6,
        name: "Crispy Sky",
        style: "crsp-sky-sheet",
        dropdown: "crsp-sky-sheet-ddmenu",
        dropdownItem: "crsp-sky-sheet-dditem",
        scroll: "crsp-sky-sheet-thumb",
        select: "crsp-sky-sheet-select",
        iconColor: { color: iconColors.crsp_sky },
    },
    {
        id: 7,
        name: "Light Olive",
        style: "lght-olv-sheet",
        dropdown: "lght-olv-sheet-ddmenu",
        dropdownItem: "lght-olv-sheet-dditem",
        scroll: "lght-olv-sheet-thumb",
        select: "lght-olv-sheet-select",
        iconColor: { color: iconColors.lght_olv },
    },
    {
        id: 8,
        name: "Halloween",
        style: "hllwn-sheet",
        dropdown: "hllwn-sheet-ddmenu",
        dropdownItem: "hllwn-sheet-dditem",
        scroll: "hllwn-sheet-thumb",
        select: "hllwn-sheet-select",
        iconColor: { color: iconColors.hllwn },
    },
];

export { themeOptions, getThemeById };
