// datamuse.js
const baseUrl = "https://api.datamuse.com/words/";

const cachedLines = {};

const formatText = (inputText) => {
    // Convert integers to words
    const textWithWords = inputText.replace(/\b\d+\b/g, numberToWord);

    // Remove punctuation using regular expression
    const textWithoutPunctuation = textWithWords.replace(/[^\w\s]+/g, "");

    // Replace whitespace with "+"
    const textWithoutSpaces = textWithoutPunctuation.replace(/\s+/g, "+");

    return textWithoutSpaces;
}

const numberToWord = (number) => {
    const words = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
    ];

    const tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
    ];

    if (number < 20) {
        return words[number];
    }

    if (number < 100) {
        return (
            tens[Math.floor(number / 10)] +
            (number % 10 !== 0 ? "-" + words[number % 10] : "")
        );
    }

    if (number < 1000) {
        return (
            words[Math.floor(number / 100)] +
            " hundred" +
            (number % 100 !== 0 ? " and " + numberToWord(number % 100) : "")
        );
    }

    if (number < 1000000) {
        return (
            numberToWord(Math.floor(number / 1000)) +
            " thousand" +
            (number % 1000 !== 0 ? " " + numberToWord(number % 1000) : "")
        );
    }

    if (number < 1000000000) {
        return (
            numberToWord(Math.floor(number / 1000000)) +
            " million" +
            (number % 1000000 !== 0 ? " " + numberToWord(number % 1000000) : "")
        );
    }

    return "Number is too large to convert.";
};

export const extractNonEmptyLines = (text) => {
    const lines = text.split('\n'); // Split the text into lines
    const nonEmptyLines = lines.filter(line => line.trim() !== ''); // Filter out empty lines
    return nonEmptyLines;
  }

export const fetchLineData = async (line) => {
    line = formatText(line);
    let endpoint = `?rel_rhy=${line}&md=sd&qe=rel_rhy&max=10`;

    try {
        const response = await fetch(baseUrl+endpoint);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();

        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

// create fetchLineDataV2 that support caching
export const fetchLineDataV2 = async (line) => {
    line = formatText(line);
    let endpoint = `?rel_rhy=${line}&md=sd&qe=rel_rhy&max=10`;

    try {
        if (cachedLines[line]) {
            return cachedLines[line];
        }
        const response = await fetch(baseUrl+endpoint);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        cachedLines[line] = data;

        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};