import React, { Component } from "react";
// import "./App.css";
import Sheet from "./components/sheet/Sheet";

class App extends Component {
  render() {
    return <Sheet />;
  }
}

export default App;
